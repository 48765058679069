<template>
  <div class="home norem-home">
    <div class="banner-scroll">
      <img class="home-banner" src="./static/background-img.png" alt="" />
    </div>
    <div class="home-down-load-btn">
      <img v-if="isAndroidBtn" class="button" @click="onDownload('androidUrl')" src="./static/android-btn.png" alt="" />
      <img v-if="isIosBtn" class="button" @click="onDownload('iosUrl')" src="./static/ios-btn.png" alt="" />

    </div>
    <img v-if="isShowWxTips" class="home-tips" src="./static/tips.png" alt="" />
  </div>
</template>
<script type="text/javascript" src="https://res.wx.qq.com/open/js/jweixin-1.3.2.js"></script>

<script>
export default {
  name: "Download",

  data() {
    return {
      isShowWxTips: false
    }
  },

  computed: {
     isEnterWx() {
      const ua = navigator.userAgent.toLowerCase();
      return ua.match(/MicroMessenger/i) == "micromessenger"
    },

    isMobileAgent() {
      return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)
    },

    isAndroidAgent() {
      return /(Android)/i.test(navigator.userAgent)
    },

    isIosAgent() {
      return /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)
    },

    isAndroidBtn() {
      return !this.isMobileAgent || this.isAndroidAgent 
    },

    isIosBtn() {
      return !this.isMobileAgent || this.isIosAgent 
    }
  },

  methods: {
    onDownload(string) {
      if (this.isEnterWx) return  this.isShowWxTips = true
      const UrlMap = new Map([['androidUrl', 'https://www.aphoto.cn/client/aphoto.apk'], ['iosUrl',  'https://apps.apple.com/cn/app/id1630061348']])
      const url = UrlMap.get(string) || ''
      window.open(url, '_self')
    },

    // onClickAndroid(string) {
    //   if (this.isEnterWx) return  this.isShowWxTips = true
    //   this.onDownload(string)
    // },

    // async onClickIos(string) {
    //     this.onDownload(string)
    // }
  },

  mounted() {
    // alert(this.isMobileAgent)
  }


};
</script>

<style lang="scss" >
.home {
  position: relative;
  background-color: rgb(18, 18, 19);
  // overflow: auto;

  .banner-scroll {
    // max-height: 100%;
    height: 100vh;
    overflow-y: auto;
  }

  &-banner {
    width: 375px;
  }

  &-down-load-btn {
    position: absolute;
    bottom: 68px;
    // left: 50%;
    // transform: translateX(-50%);
    left: 20px; // width: 276px;
    height: 44px;
    width: 335px;
    display: flex;
    justify-content: center;

    .button {
      width: 160px;

      &:nth-child(2) {
        margin-left: 15px;
      }
    }
  }

  &-tips {
    position: absolute;
    top: 0;
    right: 15px;
    width: 121px;
    height: 67px;
  }
}
</style>

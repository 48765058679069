/* 首页模块管理 */
import request from '@/utils/request'

// 获取挑战url小程序
export function getUrlLink(data) {
  return request({
    baseURL: 'https://mini.aphoto.cn',
    url: '/wx/activityPhotography/getCommonUrlLinkBate',
    method: 'post',
    data
  })
}

// 获取挑战url小程序（通用）
// export function getCommonUrlLink(data) {
//   return request({
//     url: '/wx/activityPhotography/getCommonUrlLink',
//     method: 'post',
//     data
//   })
// }

// 验证码
export function getCaptcha(data) {
  return request({
    url: '/wx/auth/regCaptcha',
    method: 'post',
    data
  })
}
export function getActivityPhotographyUserImg(params) {
  return request({
    url: '/wx/activityPhotography/getActivityPhotographyUserImg',
    method: 'GET',
    params
  })
}


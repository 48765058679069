<template>
  <!-- <div>432432</div> -->
</template>

<script>
import { getUrlLink } from '@/api/home'

export default {
  name: 'Wxapp',

  data() {
    return {
      // link: 'pages/index/index?pages=/pagesB/tiktokShop/SearchOrderSn'
    }
  },

  mounted() {
    this.jumpToWx()
  },

  methods: {
    async jumpToWx() {
      const res = await getUrlLink({
        path: `/pages/index/index`,
        query: `pages=/pagesB/tiktokShop/SearchOrderSn`,
        days: '1',
        envVersion: 'release',
      })
      window.location.href = res.data.url_link
    }
  }

}
</script>

import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import Download from '../views/Download.vue'
import Wxapp from '../views/Wxapp.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Download',
    component: Download,
    meta: {
      title: '下载页面'
    }
  },
  {
    path: '/wxapp',
    name: 'Wxapp',
    component: Wxapp,
    meta: {
      title: '正在打开雅昌商城'
    }
  }
]

const router = new VueRouter({
  // mode: 'history',
  routes
})

export default router
